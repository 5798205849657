(function (App, $, window, document) {
  /** @type {Boolean} Is local debug active? */
  const localDebug = false;
  /** @type {Boolean} Is debug active? */
  const debug = App.hasDebug() && localDebug;
  /** @type {Function} Log helper */
  const { log } = App.components.Debugger(debug, 'Modal');

  /** @type {Object} Alias for the `document` jQuery object */
  const $document = $(document);

  /** @type {Object} Defaults options */
  const defaults = {
    modalSelector: '#js-modal',
    contentSelector: '.js-modal-content',
    openTriggerSelector: '.js-modal-open',
    closeTriggerSelector: '.js-modal-close',
    beforeOpen() {},
    afterOpen() {},
    beforeClose() {},
    afterClose() {},
    type: 'full',
  };

  /**
   * Return an object with dynamic values
   * for the viewport width and height
   *
   * @return {Object} Object containing the width and height of the viewport
   */
  function Modal(options) {
    if (!(this instanceof Modal)) return new Modal(options);
    log('constructor');

    this.options = $.extend(true, {}, defaults, options);
    this.$modal = $(this.options.modalSelector);
    this.$content = this.$modal.find(this.options.contentSelector);
    this.$closeTriggers = this.$modal.find(this.options.closeTriggerSelector);
    this.isInit = false;
    this.isOpen = false;
    this.isClosing = false;
    this.eventBtn = null;

    return this;
  }

  /**
   * Init the modal
   *
   * @return {Object} The current instance
   */
  function init() {
    log('init', this.isInit);

    // Reset the selectors on init
    this.$modal = $(this.options.modalSelector);
    this.$content = this.$modal.find(this.options.contentSelector);
    this.$closeTriggers = this.$modal.find(this.options.closeTriggerSelector);

    // Bind opening events
    if (this.options.openTriggerSelector !== null) {
      $document.on('click', this.options.openTriggerSelector, this.openClickHandler.bind(this));
    }

    // Bind closing events
    $document.on('click', this.options.closeTriggerSelector, this.closeClickHandler.bind(this));

    this.isInit = true;

    return this;
  }

  /**
   * Destroy the modal
   *
   * @return {Object} The current instance
   */
  function destroy() {
    log('destroy');
    // Unbind opening events
    $document.off('click', this.options.openTriggerSelector, this.openClickHandler.bind(this));

    // Unbind closing events
    $document.off('click', this.options.closeTriggerSelector, this.closeClickHandler.bind(this));

    this.isInit = false;

    return this;
  }

  /**
   * Open the modal with the given content
   *
   * @param  {String}   content  		The content to add to the modal
   * @param  {String}   type     		The type of modal
   * @param  {Function} callback 		A function to be executed after the opening
   * @param  {String}   modalClass  Add custom Class to the modal
   * @return {Object}            		The current instance
   */
  function open(content, type, callback, modalClass) {
    log('open');

    if (typeof this.options.beforeOpen === 'function') {
      this.options.beforeOpen(this);
    }

    // Content square tracking
    if (this.eventBtn && this.eventBtn.data('options').csTracking) {
      window._uxa.push([
        'trackPageview',
        `${window.location.pathname + window.location.hash.replace('#', '?__')}?${
          this.eventBtn.data('options').csTracking
        }`,
      ]);
    }

    // Always reset the modal classes before opening
    this.$modal.removeAttr('class').addClass('modal');

    if (type) {
      this.$modal.addClass(`modal--${type}`);
    }

    if (modalClass) this.$modal.find('.modal__container').addClass(modalClass);

    // Disable page scroll
    this.disableScroll();

    // Append content
    this.$content.html(content);

    // Bind close events
    $document.on('keyup', this.keyupHandler.bind(this));

    // To allow js binding if necessary
    $document.trigger('openmodal');

    // Show modal
    this.$modal.addClass('is-open').attr('aria-hidden', false);

    $(this).trigger('modal:open');

    // Execute callback
    if (typeof callback === 'function') {
      callback(this.$modal);
    }

    if (typeof this.options.afterOpen === 'function') {
      this.options.afterOpen(this);
    }

    this.isOpen = true;

    return this;
  }

  /**
   * Remove Modal content
   * @return {Object} The current instance
   */
  function removeContent() {
    this.$content.html('');
  }

  /**
   * Close the modal
   *
   * @param  {Function} callback The callback to execute on close
   * @return {Object}            The current instance
   */
  function close() {
    log('close');

    this.isOpen = false;
    this.isClosing = true;

    if (typeof this.options.beforeClose === 'function') {
      this.options.beforeClose(this);
    }

    // Content square tracking
    if (this.eventBtn && this.eventBtn.data('options').csTracking) {
      window._uxa.push([
        'trackPageview',
        window.location.pathname + window.location.hash.replace('#', '?__'),
      ]);
    }

    // Unbind close events
    $document.off('keyup', this.keyupHandler);

    // Enable scroll
    this.enableScroll();

    // Hide modal
    this.$modal.removeClass('is-open').attr('aria-hidden', true);

    // Remove content after animation end
    setTimeout(
      function () {
        removeContent.call(this);
        this.isClosing = false;
        if (typeof this.options.afterClose === 'function') {
          this.options.afterClose(this);
        }
      }.bind(this),
      400
    );

    $(this).trigger('modal:close');

    return this;
  }

  /**
   * Handler for the keyup event on the document
   * which will close the modal if the key is escape
   *
   * @param {Object} e The event's object
   */
  function keyupHandler(e) {
    if (e.which === 27) {
      this.close.call(this);
    }
  }

  /**
   * Handler for the click on modal opening buttons
   *
   * @param  {Object} e The event's object
   */
  function openClickHandler(e) {
    log('openClickHandler');
    this.eventBtn = $(e.currentTarget);
    const options = this.eventBtn.data('options');
    const type = options.type ? options.type : '';
    const content = $(options.contentHolderSelector).html();
    const { modalClass } = options;
    this.open.call(this, content, type, '', modalClass);

    if (options.preventDefault) {
      e.preventDefault();
    }
  }

  /**
   * Handler for the click on modal closing butons
   */
  function closeClickHandler() {
    log('closeClickHandler', this.options.modalSelector);
    this.close.call(this);
  }

  /**
   * Prevent page scroll when modal is open
   */
  function disableScroll() {
    // Replace the jQuery scrollTop function
    // to avoid unwanted `$(document).scrollTop`
    // when the modal is open
    this.saveScrollTopFn = $.fn.scrollTop;
    $.fn.scrollTop = function () {};
    $('html').css({ overflow: 'hidden' });
  }

  /**
   * Enable page scroll
   */
  function enableScroll() {
    $.fn.scrollTop = this.saveScrollTopFn;
    $('html').css({ overflow: '' });
  }

  // Register prototype methods
  Modal.prototype = $.extend(true, Modal.prototype, {
    init,
    destroy,
    open,
    close,
    openClickHandler,
    keyupHandler,
    closeClickHandler,
    disableScroll,
    enableScroll,
    removeContent,
  });

  // Expose globally"
  window.Modal = Modal;

  $(window).on('load', function () {
    const modal = new Modal();
    modal.init();
    App.components.modal = modal;
  });
})(App, jQuery, window, document);
